export const getOtherBulders = (load) => {
  const builderArray = [];
  if (load?.hasAirConditionerMan === "1") {
    builderArray.push("ជាងម៉ាស៊ីនត្រជាក់");
  }
  if (load?.hasBlackSmith === "1") {
    builderArray.push("ជាងដែក");
  }
  if (load?.hasCarpenter === "1") {
    builderArray.push("ជាងឈើ");
  }
  if (load?.hasCeilBuilder === "1") {
    builderArray.push("ជាងពិដាន");
  }
  if (load?.hasElectricianPlumber === "1") {
    builderArray.push("ជាងទឹកភ្លើង");
  }
  if (load?.hasMirrorBuilder === "1") {
    builderArray.push("ជាងកញ្ជក់");
  }

  if (builderArray?.length <= 0) {
    return "";
  }

  return builderArray?.join(" | ");
};
