import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import { Typography, Button, Form } from "antd";
import { Select } from "antd";
import CreateWorker from "./CreateWorker";
import BuilderBiographyTable from "./BuilderBiographyTable";
import { getWorkerWithPagination } from "../../getDatabase";
import { useRequest } from "ahooks";
import {
  postionOption,
  skillOption,
  workStatusOption,
} from "../../constant/builder";

const { Option } = Select;
const { Title } = Typography;

export default function BuilderBiography({ search }) {
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [keyword, setKeyword] = useState("");
  
  const [sortData, setSortData] = useState(null);

  const [filterData, setFilterData] = useState({
    work_status: "",
    skills: "",
    position: "",
  });

  const [tableData, setTableData] = useState({});

  const { loading, refresh, run } = useRequest(getWorkerWithPagination, {
    defaultParams: [{ page: page, pageSize: pageSize, keyword: search || "" }],
    onSuccess: (data) => {
      setTableData(data);
      console.log(data);
    },
  });

  const handleFilter = (e) => {
    setFilterData((prev) => ({
      ...prev,
      ...e,
    }));
  };

  useEffect(() => {
    run({
      page: page,
      pageSize: pageSize,
      keyword: search || "",
      ...filterData,
      sortCurrentPrice: sortData?.currentPrice
    });
  }, [filterData, page, pageSize, run, search, sortData]);

  return (
    <div
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <Row>
        {/* Header ---------------------------------------------------- */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Title
            style={{
              fontFamily: "Moul",
              color: "#1983e6",
              fontWeight: "normal",
            }}
            level={4}
          >
            {`ប្រវត្តជាង`}
          </Title>
          <Form form={form} id="customer-filter-form">
            <Row>
              <Col xs={24} sm={24} md={5} lg={5} xl={4} style={{ padding: 5 }}>
                <Form.Item name="Position">
                  <Select
                    placeholder="ប្រភេទ"
                    size="large"
                    style={{ width: "100%" }}
                    onChange={(e) => handleFilter({ position: e })}
                  >
                    <Option value={""} key="all">
                      --ទាំងអស់--
                    </Option>
                    {postionOption?.map((e) => (
                      <Option key={e} value={e}>
                        {e}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={4} style={{ padding: 5 }}>
                <Form.Item name="work_status">
                  <Select
                    placeholder="ស្ថានភាព"
                    size="large"
                    style={{ width: "100%" }}
                    onChange={(e) => handleFilter({ work_status: e })}
                  >
                    <Option value={""} key="all">
                      --ទាំងអស់--
                    </Option>
                    {workStatusOption?.map((e) => (
                      <Option key={e} value={e}>
                        {e}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={4} style={{ padding: 5 }}>
                <Form.Item name="skill">
                  <Select
                    placeholder="ជំនាញ"
                    size="large"
                    style={{ width: "100%" }}
                    onChange={(e) => handleFilter({ skills: e })}
                  >
                    <Option value={""} key="all">
                      --ទាំងអស់--
                    </Option>
                    {skillOption?.map((e) => (
                      <Option key={e} value={e}>
                        {e}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={3} lg={3} xl={3} style={{ padding: 5 }}>
                <Button
                  onClick={() => {
                    form.resetFields();
                    handleFilter({
                      work_status: "",
                      skills: "",
                      position: "",
                    });
                  }}
                  type="primary"
                  size="large"
                  style={{ width: "100%" }}
                >
                  ទាំងអស់
                </Button>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={{ span: 4, offset: 2 }}
                lg={{ span: 4, offset: 2 }}
                xl={{ span: 3, offset: 6 }}
                style={{ padding: 5 }}
              >
                <CreateWorker refresh={refresh} />
              </Col>
            </Row>
          </Form>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <BuilderBiographyTable
            tableData={tableData}
            page={page}
            setSortData={setSortData}
            pageSize={pageSize}
            loading={loading}
            setPage={setPage}
            setPageSize={setPageSize}
            refresh={refresh}
          />
        </Col>

        <Col xs={24} sm={24} md={16} lg={16} xl={12}>
          <Card
            style={{ width: "100%", background: "none",border:'none', margin: "15px 0px",padding:10 }}
          >
            <Row>
              <Col xs={10} md={6}>
                <Typography.Title level={5} style={{ fontWeight: "bold" }}>
                  - សរុបទាំងអស់
                </Typography.Title>
              </Col>
              <Col xs={14} md={6}>
                <Typography.Title level={5} style={{ fontWeight: "bold" }}>
                  :&emsp;{tableData?.totalDoc}
                </Typography.Title>
              </Col>
            </Row><br/>

            <Row>
              {workStatusOption?.map((item) => {
                let work_status = tableData?.total_work_status?.find(
                  (e) => e?.work_status === item
                );

                return (
                  <>
                    <Col xs={10} md={6}>
                      <p level={5}>- សរុប{item}</p>
                    </Col>
                    <Col xs={14} md={6}>
                      <p level={5}>:&emsp;{work_status?.total || 0}</p>
                    </Col>
                  </>
                );
              })}
              {/* {tableData?.total_work_status?.map((item) => (
                <>
                  <Col xs={6} md={6}>
                    <p level={5}>- សរុប{item?.work_status}</p>
                  </Col>
                  <Col xs={18} md={6}>
                    <p level={5}>:&emsp;{item?.total}</p>
                  </Col>
                </>
              ))} */}
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
