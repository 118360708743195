import React, { useEffect, useState } from "react";
import { Row, Col, Input, Popconfirm, message } from "antd";
import { useLocation } from "react-router-dom";
import { delete_cookie, getCookie } from "../../getDatabase";
import ChristmasMusic from "../../assets/music/christmas-song.mp3";
import JingleBellMusic from "../../assets/music/jingle-bell.mp3";
import WeWishYou from "../../assets/music/we-wish-you.mp3";
import "../styles/Header.css";
import { isClickedSanta, isOpenOnceAtom } from "../../recoils/eventState/atom";
import { useRecoilState } from "recoil";
import useSound from "use-sound";
import NewYearModal from "./NewYearModal";
import moment from "moment";
const { Search } = Input;

const getMusic = (id) => {
  if (id === 3) return 1;
  return id + 1;
};

const randomId = () => {
  const rndInt = Math.floor(Math.random() * (3 - 1 + 1)) + 1;
  return rndInt;
};

export default function Header({ setAuth, setSearch, search }) {
  const { pathname } = useLocation();
  const [isSearch, setIsSearch] = useState(true);
  const [positionPop, setPositionPop] = useState([]);
  // new year
  const [openNewYear, setOpenNewYear] = useState(false);
  const [isOpenOnce, setIsOpenOnce] = useRecoilState(isOpenOnceAtom);
  // xmas
  const [isClicked, setIsClicked] = useRecoilState(isClickedSanta);
  const [santaClass, setSantaClass] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [musicId, setMusicId] = useState(randomId());
  const [play, { stop }] = useSound(ChristmasMusic, {
    interrupt: true,
  });

  const [play2, { stop: stop2 }] = useSound(JingleBellMusic, {
    interrupt: true,
  });

  const [play3, { stop: stop3 }] = useSound(WeWishYou, {
    interrupt: true,
  });

  useEffect(() => {
    // setTimeout(() => setSantaClass("active-animate"), 5000);
    // setTimeout(() => {
    //   if (!isClicked) {
    //     setShowMessage(true);
    //   }
    // }, 7000);
    setTimeout(() => {
      if (moment().format("YYYY-MM-DD") >= "2024-01-01" && !isOpenOnce) {
        setIsOpenOnce(true);
        setOpenNewYear(true);
      }
    }, 5000);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 500) {
        setPositionPop(["top", "left"]);
      } else {
        setPositionPop(["top", "right"]);
      }
    });
    window.addEventListener("load", () => {
      if (window.innerWidth <= 500) {
        setPositionPop(["top", "left"]);
      } else {
        setPositionPop(["top", "right"]);
      }
    });
  }, []);

  useEffect(() => {
    setSearch(null);
    if (
      pathname !== "/" &&
      pathname !== "/report" &&
      !pathname?.includes("/builder/") &&
      pathname !== "/watermark" &&
      pathname !== "/report/print"
    ) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
  }, [pathname]);

  const handlePlayMusic = () => {
    setIsClicked(true);
    setShowMessage(false);
    setMusicId(getMusic(musicId));

    if (musicId === 1) {
      stop2();
      stop3();
      play();
    } else if (musicId === 2) {
      stop();
      stop3();
      play2();
    } else if (musicId === 3) {
      stop();
      stop2();
      play3();
    }
  };

  return (
    <>
      {/* <NewYearModal open={openNewYear} setOpen={setOpenNewYear} /> */}
      <div style={{ position: "relative" }}>
        {/* <Popover
          isOpen={showMessage}
          positions={positionPop} // preferred positions by priority
          content={
            <div
              style={{
                padding: "5px 25px",
                position: "relative",
              }}
            >
              <span style={{ zIndex: 20, position: "relative", fontSize: 16 }}>
                Click Me
              </span>
              <img
                src={BoxChat}
                width={120}
                alt=""
                onKeyDown={() => {}}
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  zIndex: 10,
                  transform: positionPop.includes("left")
                    ? "rotateY(180deg)"
                    : null,
                }}
              />
            </div>
          }
        >
          <img
            src={SantaWave}
            width={75}
            alt=""
            className={`wave-santa ${santaClass}`}
            onClick={handlePlayMusic}
          />
        </Popover> */}

        <Row
          style={{
            paddingTop: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 14, order: 0 }}
            md={{ span: 9, order: 0 }}
            lg={{ span: 8, order: 0 }}
            xl={{ span: 6, order: 0 }}
            style={{ paddingTop: 10 }}
          >
            {isSearch ? (
              <Search
                allowClear
                value={search}
                enterButton="ស្វែងរក"
                size="large"
                onChange={(e) => setSearch(e.target.value)}
              />
            ) : null}
          </Col>
          <Col
            xs={{ span: 24, order: 0 }}
            sm={{ span: 8, offset: 2, order: 1 }}
            md={{ span: 6, offset: 9, order: 1 }}
            lg={{ span: 5, offset: 11, order: 1 }}
            xl={{ span: 4, offset: 14, order: 1 }}
          >
            <center>
              <p
                style={{
                  marginTop: 10,
                  // position: "absolute",
                  right: 0,
                  cursor: "pointer",
                  fontSize: 16,
                }}
              >
                <Popconfirm
                  placement="topRight"
                  title={"តើអ្នកចង់ចាកចេញ?"}
                  onConfirm={() => {
                    delete_cookie("u_id");
                    delete_cookie("username");
                    message.success("ជោគជ័យ!!");
                    setAuth(true);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <span style={{ color: "red" }}>ចាកចេញ</span>
                </Popconfirm>
                <span style={{}}>{` | ${getCookie(
                  "username"
                )?.toUpperCase()}`}</span>
              </p>

            </center>
          </Col>
        </Row>
      </div>
    </>
  );
}
