import { Card, Col, Row, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { checkStatus } from "../../own-comp";
import Progresss from "./Progresss";
import TagBuilder from "./TagBuilder";
import { RiCheckboxBlankCircleFill, RiCheckboxFill } from "react-icons/ri";
import TeamTmr from "./TeamTmr";

export default function CardDailyConstruct({
  todo: todoData,
  setOpenDetail,
  setDailyConstructId,
}) {
  const [paddingBottom, setPaddingBottom] = useState(130);

  const bottomBoxRef = useRef();

  const todo = {
    ...todoData,
    performances: todoData?.performances?.map((e) => ({
      ...e,
      isContract: parseInt(e?.isContract, 10) > 0 ? true : false,
      isDone: parseInt(e?.isDone, 10) > 0 ? true : false,
    })),
  };

  const handleOpen = (e) => {
    setDailyConstructId(e);
    setOpenDetail(true);
  };

  useEffect(() => {
    const bottomHeight = bottomBoxRef?.current?.clientHeight;
    setPaddingBottom(bottomHeight - 20);

    // let totalBuilder =
    //   parseInt(todo?.hasElectricianPlumber) +
    //   parseInt(todo?.hasAirConditionerMan) +
    //   parseInt(todo?.hasBlackSmith) +
    //   parseInt(todo?.hasCeilBuilder) +
    //   parseInt(todo?.hasMirrorBuilder) +
    //   parseInt(todo?.hasCarpenter);
    // if (totalBuilder >= 6) {
    //   setPaddingBottom(210 + bottomHeight);
    // } else if (totalBuilder >= 3) {
    //   setPaddingBottom(170 + bottomHeight);
    // } else if (totalBuilder >= 1) {
    //   setPaddingBottom(130 + bottomHeight);
    // } else {
    //   setPaddingBottom(50 + bottomHeight);
    // }
  }, [todo]);

  return (
    <>
      <Card
        bordered={false}
        style={{
          borderRadius: 10,
          position: "relative",
          paddingBottom: paddingBottom,
        }}
        className={`card-report ${
          checkStatus(todo?.status) === 100 ? "success-bg" : ""
        }`}
        cover={
          <img
            className="image-card"
            alt="example"
            onClick={() => handleOpen(todo?.dc_id)}
            src={
              todo?.customerImage
                ? `${process.env.React_App_IMAGES}/${todo.customerImage}`
                : "https://www.chanchao.com.tw/ctg/images/default.jpg"
            }
          />
        }
      >
        <div className="top-seciton" style={{ padding: 10 }}>
          <span onClick={() => handleOpen(todo?.dc_id)}>
            <Typography
              style={{
                fontWeight: "bold",
                textAlign: "center",
                fontFamily: "Moul",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              {`${todo.constructionName}`}
            </Typography>
          </span>
          <br />
          <Typography style={{ fontWeight: "bold" }}>
            {/* {`${todo.chiefName} (${todo.teamCount} ក្រុម)`} */}
            មេកា៖ {`${todo?.chiefName}`} ({todo.teamCount} ក្រុម -{" "}
            {parseInt(todo?.builderCount) + parseInt(todo.workerCount)} នាក់)
          </Typography>
          <Typography
            style={{ fontWeight: "bold", fontSize: 12, color: "#aaaaaa" }}
          >
            {/* {`ជាង៖ ${todo.builderCount} | ក.ប្រុស៖ ${parseInt(todo.workerCount) - parseInt(todo.femaleWorkerCount)} | ក.ស្រី៖ ${todo.femaleWorkerCount}`} */}
            ជាងៈ {parseInt(todo?.builderCount) - parseInt(todo?.painterCount)} |
            ជាងថ្នាំៈ {todo?.painterCount} | ប្រុសៈ{" "}
            {parseInt(todo.workerCount) - parseInt(todo?.femaleWorkerCount)} |
            ស្រីៈ {todo?.femaleWorkerCount}
          </Typography>

          <div style={{ padding: 15 }}>
            {todo.performances?.map((per, index) => (
              <>
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "start", gap: 5 }}
                >
                  <span>
                    {per?.isDone ? (
                      <RiCheckboxBlankCircleFill color="lime" size={7} />
                    ) : (
                      <RiCheckboxBlankCircleFill size={7} />
                    )}
                  </span>
                  <Typography
                    style={{
                      color: per?.isContract ? "#1599ee" : "#000000",
                      fontWeight: "bold",
                    }}
                  >
                    {per?.isContract ? (
                      <span style={{ color: "rgb(135, 187, 237)" }}>(ម៉ៅ)</span>
                    ) : null}{" "}
                    {per.performance}
                  </Typography>
                </div>
              </>
            ))}
          </div>

          {todo.challenges !== "" ? (
            <Typography style={{ color: "red" }}>
              ផ្សេងៗ:{todo.challenges}
            </Typography>
          ) : null}
        </div>

        <div
          style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
          ref={bottomBoxRef}
        >
          <Row style={{ padding: 10 }}>
            {todo?.hasElectricianPlumber === "1" ? (
              <Col xs={12} md={12} style={{ padding: 5 }}>
                <TagBuilder title={`ជាងទឹកភ្លើង`} />
              </Col>
            ) : null}
            {todo?.hasCeilBuilder === "1" ? (
              <Col xs={12} md={12} style={{ padding: 5 }}>
                <TagBuilder title={`ជាងពិដាន`} />
              </Col>
            ) : null}
            {todo?.hasBlackSmith === "1" ? (
              <Col xs={12} md={12} style={{ padding: 5 }}>
                <TagBuilder title={`ជាងដែក`} />
              </Col>
            ) : null}
            {todo?.hasAirConditionerMan === "1" ? (
              <Col xs={12} md={12} style={{ padding: 5 }}>
                <TagBuilder title={`ជាងម៉ាស៊ីនត្រជាក់`} />
              </Col>
            ) : null}
            {todo?.hasMirrorBuilder === "1" ? (
              <Col xs={12} md={12} style={{ padding: 5 }}>
                <TagBuilder title={`ជាងកញ្ជក់`} />
              </Col>
            ) : null}
            {todo?.hasCarpenter === "1" ? (
              <Col xs={12} md={12} style={{ padding: 5 }}>
                <TagBuilder title={`ជាងឈើ`} />
              </Col>
            ) : null}

            <Col xs={24} md={24}>
              <Progresss status={todo?.status} />
            </Col>
          </Row>

          <TeamTmr team={todo?.team_tmr || []} />
        </div>
      </Card>
    </>
  );
}
