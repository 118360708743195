import { Button, Checkbox, Col, Row, Slider } from "antd";
import vklogo from "../../Image/vk-logo.png";
import vkPattern from "../../Image/pattern-vk.png";
import { useState } from "react";
import html2canvas from "html2canvas";
import downloadjs from "downloadjs";
import moment from "moment";
import { IoMdDownload } from "react-icons/io";

export default function ImageWatermark({ selectedImage, index }) {
  const [logoSize, setLogoSize] = useState(130);
  const [patternSize, setPatternSize] = useState(30);
  const [patternOpacity, setPatternOpacity] = useState(20);
  const [textSize, setTextSize] = useState(17);

  // const [logoX, setLogoX] = useState();

  const [showLogo, setShowLogo] = useState(true);
  const [showText, setShowText] = useState(true);
  const [showPattern, setShowPattern] = useState(true);

  const [loading, setLoading] = useState(false);

  const handleCaptureClick = async () => {
    setLoading(true);
    const printContent = document.querySelector(`#download-body-${index}`);
    if (!printContent) return;

    const canvas = await html2canvas(printContent, {
      allowTaint: true,
      useCORS: true,
      scale: 2,
    });
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(
      dataURL,
      `image-${moment().format("YYYY-MM-DD-HHmmss")}.png`,
      "image/png"
    );
    setLoading(false);
  };

  // const handleDrag = (type, dragCtx) => {
  //   const x = dragCtx?.clientX || 0;
  //   console.log(x);
  //   // setLogoX(x);
  // };


  return (
    <Row>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={12}
        xl={12}
        style={{ paddingBottom: 15 }}
      >
        {selectedImage && (
          <div
            style={{
              marginTop: 15,
              position: "relative",
              overflow: "hidden",
              padding: 0,
            }}
            id={`download-body-${index}`}
          >
            {/* logo */}
            {showLogo && (
              <img
                // onDrag={(e) => handleDrag("logo", e)}
                // onDragEnd={(e) => handleDrag("logo", e)}
                src={vklogo}
                width={logoSize}
                style={{
                  position: "absolute",
                  // transform: `translateX(${logoX}px)`,
                  right: 30,
                  top: 20,
                  zIndex: 20,
                }}
              />
            )}
            {/* pattern */}
            {showPattern && (
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "100%",
                  height: "100%",
                  padding: 15,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    // background: "blue",
                    backgroundImage: "url('./pattern-vk.png')",
                    backgroundRepeat: "repeat",
                    backgroundSize: `${patternSize}%`,
                    opacity: patternOpacity / 100,
                  }}
                />
              </div>
            )}
            {showText && (
              <div
                style={{
                  position: "absolute",
                  bottom: 20,
                  left: 0,
                  width: "100%",
                  textAlign: "center",
                  color: "#FFFFFF",
                  fontSize: textSize,
                }}
              >
                ក្រុមហ៊ុនម៉ៅការសាងសង់ វីខេ អង្គរ | 012 964 912 | 069 964 912
              </div>
            )}
            {/* end logo */}
            <img
              src={URL.createObjectURL(selectedImage)}
              style={{
                maxWidth: "100%",
                margin: 0,
                minWidth: "100%",
                minHeight: "100%",
                maxHeight: "100%",
              }}
            />
          </div>
        )}
      </Col>

      <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ padding: 15 }}>
        {selectedImage && (
          <>
            <div style={{ background: "#FFFFFF", padding: 10 }}>
              <div>
                <div>
                  <Checkbox
                    checked={showLogo}
                    onChange={(e) => setShowLogo(e.target.checked)}
                  />{" "}
                  Logo size:{" "}
                </div>
                {showLogo && (
                  <Slider
                    min={50}
                    defaultValue={130}
                    max={400}
                    onChange={(value) => setLogoSize(value)}
                  />
                )}
              </div>

              <div style={{ marginTop: 15 }}>
                <div>
                  <Checkbox
                    checked={showText}
                    onChange={(e) => setShowText(e.target.checked)}
                  />{" "}
                  Text size:
                </div>
                {showText && (
                  <Slider
                    min={5}
                    defaultValue={17}
                    max={30}
                    onChange={(value) => setTextSize(value)}
                  />
                )}
              </div>

              <div style={{ marginTop: 15 }}>
                <div>
                  <Checkbox
                    checked={showPattern}
                    onChange={(e) => setShowPattern(e.target.checked)}
                  />{" "}
                  Patten:
                </div>
                {showPattern && (
                  <>
                    <div style={{ marginLeft: 10, marginTop: 5 }}>Size</div>
                    <Slider
                      min={2}
                      defaultValue={30}
                      max={50}
                      onChange={(value) => setPatternSize(value)}
                    />
                    <div style={{ marginLeft: 10, marginTop: 5 }}>Opacity</div>
                    <Slider
                      min={0}
                      defaultValue={20}
                      max={100}
                      onChange={(value) => setPatternOpacity(value)}
                    />
                  </>
                )}
              </div>
            </div>

            <div style={{ marginTop: 20 }}>
              <Button
                size="large"
                onClick={handleCaptureClick}
                disabled={loading}
                loading={loading}
              >
                <IoMdDownload /> ទាញយក
              </Button>
            </div>
          </>
        )}
      </Col>
    </Row>
  );
}
