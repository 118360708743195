import { Row, Col, Divider } from "antd";
import React from "react";
import { IoIosPeople } from "react-icons/io";

export default function Teams({ team: teamData }) {
  const team = {
    ...teamData,
    isContract: parseInt(teamData?.isContract, 10) > 0 ? true : false,
    isDone: parseInt(teamData?.isDone, 10) > 0 ? true : false,
  };

  const resultWithIsDone = () => {
    if (team?.result !== "") {
      return (
        <>
          {team?.isDone ? (
            <span style={{ color: "#32CD32" }}>(រួចរាល់)</span>
          ) : (
            <>
              {team?.isContract ? (
                <span style={{ color: "rgb(255, 211, 89)" }}>(មិនរួច)</span>
              ) : null}
            </>
          )}{" "}
          {team?.result}
        </>
      );
    }

    return team?.result;
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <Row
        style={{
          fontSize: "11pt",
          background: "#EEEEEE",
        }}
      >
        <Col xs={5} className="team-padding" style={{ fontWeight: "bold" }}>
          ក្រុម {team?.teamNumber}
        </Col>
        <Col xs={5} className="team-padding">
          <b>: {team?.teamLeaderName}</b>
        </Col>
        <Col xs={14} className="team-padding">
          <b>
            ជាង: {team?.builderCount} | កម្មករ: {team?.workerCount}
          </b>
        </Col>
      </Row>
      <Row style={{ fontSize: "15px", marginTop: 5 }}>
        <Col xs={5} className="team-padding">
          ការងារ
        </Col>
        <Col xs={19} className="team-padding">
          :{" "}
          <b>
            {team?.isContract ? (
              <span style={{ color: "rgb(135, 187, 237)" }}>(ម៉ៅ)</span>
            ) : null}{" "}
            {team?.performance}
          </b>
        </Col>
      </Row>
      <Row style={{ fontSize: "15px" }}>
        <Col xs={5} className="team-padding">
          លទ្ទផល
        </Col>
        <Col xs={19} className="team-padding">
          : <b>{resultWithIsDone()}</b>
        </Col>
      </Row>
    </div>
  );
}
